// import React
import React, { useEffect, useState } from "react"
import parse from "html-react-parser"

// import header
import PreHeader from "../components/preHeader"
import Header from "../components/header"
import ListDealOfPartners from "../components/cards/listDealOfPartners"
// import footer
import Footer from "../components/footer"

import AdviceModal from "../components/modalAdvice"

import AdvantageModal from "../components/modalAdvantage"
// importing bootstrap elements
import { Container, Row, Col, Button } from "react-bootstrap"

import avatarDefault from "../images/avatar.png"
import headerDefault from "../images/header_866x250px_default.jpg"

import "../styles/main.scss"

// importing translate
import {
  AUTH_TOKEN_KEY,
  DOMAIN_API,
  URL_GETALL,
  FR,
  getUserLanguage,
  NL,
  PARAM_ID,
  translate,
  URL_GETONE_PARTNERSUPER,
} from "../utils"

// importing Logo (photo)
import ModalAlert from "../components/modalAlert"
import axios from "axios"
import TagManager from "react-gtm-module"
import ReactGA from "react-ga" 

const Partners = () => {
  const [name, setname] = useState("")
  const [description, setdescription] = useState("")
  const [descriptionFR, setdescriptionFR] = useState("")
  const [avatarImg, setavatarImg] = useState(avatarDefault)
  const [headerImg, setheaderImg] = useState(headerDefault)
  const [language, setLanguage] = useState("")
  const [email, setemail] = useState("")
  const [showAlert, setshowAlert] = useState(false)
  const [alertTitle, setalertTitle] = useState("")
  const [alertMessage, setalertMessage] = useState("")
  const [isRendered, setisRendered] = useState(false)
  const [idPar, setidPar] = useState()
  const [allFeed, setallFeed] = useState([])
  const [activeAdvantage, setactiveAdvantage] = useState(undefined)
  const [showAdvantageModal, setshowAdvantageModal] = useState(false)
  const [showAdviceModal, setshowAdviceModal] = useState(false)
  const [activeAdvice, setactiveAdvice] = useState(false)
  const [items, setitems] = useState([])
  useEffect(() => {
    setLanguage(getUserLanguage())
    getPartnerData()
  }, [])

  const toggleAdvantageModal = () => {
    if (!showAdvantageModal) {
      activeAdvantage(items)
    } else {
      activeAdvantage(undefined)
      window.history.replaceState({}, document.title, "/" + "feed")
    }
    showAdvantageModal(!showAdvantageModal)
  }

  const toggleAdviceModal = item => {
    if (!this.state.showAdviceModal) {
      setactiveAdvice(item)
    } else {
      setactiveAdvice(undefined)
      window.history.replaceState({}, document.title, "/" + "feed")
    }
    setshowAdviceModal(!this.state.showAdviceModal)
  }

  const getPartnerData = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }

    if (window !== undefined) {
      const query = new URLSearchParams(window.location.search)
      if (query.get(PARAM_ID) !== null) {
        const data = {
          id: query.get(PARAM_ID),
        }
        await axios
          .post(URL_GETONE_PARTNERSUPER, data, header)
          .then(res => {
            if (res.data) {
              const {
                id,
                name,
                description,
                descriptionFR,
                avatarImg,
                headerImg,
                partner,
              } = res.data

              setidPar(id)
              setname(name)
              getAll(name)
              setdescription(description)
              setdescriptionFR(descriptionFR)
              if (
                avatarImg !== "" &&
                avatarImg !== undefined &&
                avatarImg !== null
              )
                setavatarImg(DOMAIN_API() + avatarImg)
              if (
                headerImg !== "" &&
                headerImg !== undefined &&
                headerImg !== null
              )
                setheaderImg(DOMAIN_API() + headerImg)
              if (partner && partner.account) setemail(partner.account.email)
            }
          })
          .catch(err => {
            if (err.response) {
              console.log(err.response.data)
            }
            console.log(err)
          })
      }
    }
  }

  if (!isRendered) {
    if (typeof window !== "undefined") {
      const tagManagerArgs = {
        gtmId: "GTM-N8PSVKX",
      }

      TagManager.initialize(tagManagerArgs)
    }
    ReactGA.initialize("G-1D942S8R6N")
    setisRendered(true)
  }

  const getAll = async name => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    await axios
      .get(URL_GETALL, data)
      .then(res => {
        if (res.data) {
          // item.type!='advantage'
        
          console.log(
            res.data.filter(
              item =>
                item.type != "advantage" &&
                item.type != "folder" &&
                item.s2500x500.toLowerCase().includes(name.toLowerCase())
            )
          )
          setallFeed([
            ...res.data.filter(
              item =>
                item.type != "advantage" &&
                item.type != "folder" &&
                item.s2500x500.toLowerCase().includes(name.toLowerCase())
            ),
          ])
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      })
  }

  return (
    <div>
      <PreHeader />
      <div className="privacyTerms privacyTerms-yellow-ahmad">
        <Header />
        <div className=" bg-white-p">
          <Container>
            <Row>
              <Col lg="9" className=" mt-0 pt-0">
                <img style={{ width: "100%" }} src={headerImg} />
                <div style={{ marginTop: "20px" }}>
                  <img
                    style={{ width: "128px" }}
                    //onClick={() => alert('Coming soon!')}
                    className="img-fluid partnerImg"
                    src={avatarImg}
                    alt=""
                  />
                </div>
                {console.table(description)}
                {description !== "" &&
                description !== undefined &&
                description !== null ? (
                  <div>
                    <h4 className="h2-title pt-1 pb-3">
                      {translate("description")}
                    </h4>

                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {language === FR
                        ? parse(descriptionFR)
                          ? parse(descriptionFR)
                          : ""
                        : parse(description)
                        ? parse(description)
                        : ""}
                    </p>
                  </div>
                ) : null}

               
              </Col>

              <Col className="  col-md-3  ">
                <h1 className="h1-title mb-4 ">{translate("deals")}</h1>
                <div className="">
                  <div>
                    <ListDealOfPartners   toggleAdvice={toggleAdvantageModal} />
                  </div>
                  

                  <div className="orange-widgets-box rounded-lg mt-4">
                    <h1 className="h1-title font-poppins">
                      {translate("wilt u meer voordelen") + "?"}
                    </h1>
                    <p>{translate("voltooi uw profiel")}</p>
                    <a href={"/auther-profile"}>
                      <Button variant="outline-secondary">
                        {translate("profiel wijzigen")}
                      </Button>
                    </a>
                    <p id=""></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <AdvantageModal
            show={showAdvantageModal}
            toggle={toggleAdvantageModal}
            advantage={activeAdvantage}
          />
          <AdviceModal
            show={showAdviceModal}
            toggle={toggleAdviceModal}
            advice={activeAdvice}
          />
        </div>
      </div>
      {showAlert ? (
        <ModalAlert
          alertTitle={alertTitle}
          alertMessage={alertMessage}
          closeModal={() => setshowAlert(false)}
        />
      ) : null}
      <Footer />
    </div>
  )
}

export default Partners
